<template>
  <div id="app">
    <title>WonderWash</title>
    <!-- for example router view -->
    <!-- style="background-color:#f3f3f3;" -->
    <router-view></router-view>
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import config from "../config";
import liff from "@line/liff";
const axios = require("axios");

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      profile: null,
      liffid: config.liffid,
      user: null,
      selected: "Month",
    };
  },
  mounted() {
    console.log("update v.1.0.1.3x")
    // start ทดสอบแบบ localhost
    liff.getProfile().then((profile) => {
      this.$store.state.profile = profile;
    //end ทดสอบแบบ localhost

      this.start();
      this.profile = this.$store.state.profile;
      this.$store.state.showNav = false;

      console.log("profile",this.$store.state.profile)

      this.checkUser(this.profile).then((res) => {
        console.log("checkUser", res.data);

        this.user = res.data.user;
        this.$store.state.profile.nemeuser = res.data.nemeuser;
        this.$store.state.profile.numberphone = res.data.numberphone;
        this.$store.state.profile.branch = res.data.branch;
        this.$store.state.profile.sex = res.data.sex;

        const profileData = JSON.stringify(this.$store.state.profile);
        localStorage.setItem("profileData", profileData);

        this.API_checkAskPin({ userId: this.profile.userId }).then((res) => {
          // console.log("API_checkAskPin", res.data);
          let setting = {};
          setting.AskPinLine = res.data.AskPinLine;
          setting.AskPinKiosk = res.data.AskPinKiosk;
          setting.LineNotification = res.data.LineNotification;
          setting.SMSNotification = res.data.SMSNotification;
          this.$store.state.profile.setting = setting;

          this.API_machinelist().then((ress) => {
            // console.log("API_machinelist", ress.data)

            this.$store.state.profile.branchWW = [...ress.data];

            this.$store.state.profile.branchWW_org = [...ress.data];
            var data = this.$store.state.profile.branchWW;
            for (var i = 0; i < data.length; i++) {
              if (
                parseInt(data[i].value) ==
                parseInt(this.$store.state.profile.branch)
              ) {
                data.unshift(data[i]);
                data.splice(i + 1, 1);
              }
            }
            this.$store.state.profile.branchWW = [...data];
            // console.log("data", this.user)
            console.log("page", this.$route.query.pageww);
            if (this.user == "ready") {
              if (this.$route.query.pageww == "setting") {
                this.$router.push("/settingww");
              } else if (this.$route.query.pageww == "member") {
                this.$router.push("/memberww");
              } else if (this.$route.query.pageww == "reward") {
                this.$router.push("/rewardww");
              } else if (this.$route.query.pageww == "news") {
                this.$router.push("/newsww");
              } else if (this.$route.query.pageww == "operation") {
                this.$router.push("/operationww?tab=statusall"); //
              } else if (this.$route.query.pageww == "status") {
                this.$router.push("/operationww?tab=statusofyou"); //
              } else if (this.$route.query.pageww == "testtest") {
                this.$router.push("/testtest"); //
              } else {
                this.$router.push("/memberww");
              }
              this.$store.state.showNav = true;
            } else {
              this.$store.state.showNav = false;
              this.$router.push(
                "/registerww?pageww=" + this.$route.query.pageww
              );
            }
          });
        });
      });

    //start ทดสอบแบบ localhost
    });
    //end ทดสอบแบบ localhost
  },
  methods: {
    checkUser(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/checkuser", data, {
            headers: header,
          })
          .then(function(res) {
            resolve(res);
          })
          .catch(function(err) {
            if (error.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.header);
            }
            reject(err);
          });
      });
    },

    API_checkAskPin(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/getsetting", data, {
            headers: header,
          })
          .then(function(res) {
            resolve(res);
          })
          .catch(function(err) {
            if (error.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.header);
            }
            reject(err);
          });
      });
    },

    API_machinelist() {
      // console.log("test API_machinelist")
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .get(config.server + "/machinelist", {
            headers: header,
          })
          .then(function(res) {
            resolve(res);
            //  console.log("res", res.data);
          })
          .catch(function(err) {
            reject(err);
          });
      });
    },

    start() {
      this.$Progress.start();
    },
    set(num) {
      this.$Progress.set(num);
    },
    increase(num) {
      this.$Progress.increase(num);
    },
    decrease(num) {
      this.$Progress.decrease(num);
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
    test() {
      this.$Progress.start();

      this.$http
        .jsonp(
          "http://api.rottentomatoes.com/api/public/v1.0/lists/movies/in_theaters.json?apikey=7waqfqbprs7pajbz28mqf6vz"
        )
        .then(
          (response) => {
            this.$Progress.finish();
          },
          (response) => {
            this.$Progress.fail();
          }
        );
    },
  },
};
</script>
